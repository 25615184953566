<template>
  <div id="app">
    <vue-extend-layouts />
  </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout'
export default {
  name: 'App',
  components: { VueExtendLayouts },
  async created () {
    try {
      await this.$store.dispatch('auth/retrieveUserProfile')
    } catch (err) {
      // Supress the 'login required' error as we can illegitimately get that
      // when processing the callback url
      if (err.message.toLowerCase() !== 'login required') {
        throw err
      }
    }
  }
}
</script>
