import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export const state = () => {
  return {
    data: [],
    specialties: [],
    providerTypes: []
  }
}

export const mutations = {
  setData (state, { data, search }) {
    state.data = data || []
  },
  setSpecialties (state, data) {
    const keys = Object.keys(data.data.specialties).map((e) => e.trim())
    state.specialties = keys.filter((val, idx) => keys.indexOf(val) === idx).sort()
  },
  setProviderTypes (state, types) {
    state.providerTypes = types.sort((a, b) => {
      if (a.long > b.long) return 1
      return -1
    })
  }
}

export const actions = {
  search (context, searchTerm) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/subscriber_did_search',
      params: {
        q: searchTerm
      }
    }).then(({ data }) => {
      context.commit('setData', { data: data.data, searchTerm })
    })
  },
  getSpecialties (context, params) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/specialty',
      params
    }).then((data) => {
      context.commit('setSpecialties', data.data)
    })
  },
  retrieveProviderTypes (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/provider_type'
    }).then(({ data }) => {
      context.commit('setProviderTypes', data.data)
      return data.data
    })
  }
}

export default {
  state,
  mutations,
  actions
}
