import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export const state = () => {
  return {
    preferences: {},
    credentialProposals: [],
    count: {
      proposals: 0
    }
  }
}

export const mutations = {
  updatePreference (state, { type, method, value }) {
    state.preferences[type][method] = value
  },
  setPreferences (state, preferences) {
    state.preferences = Object.assign(state.preferences, preferences)
  },
  setCredentialProposals (state, credentialProposals) {
    state.credentialProposals = credentialProposals
  },
  setCount (state, { type, count }) {
    state.count[type] = count
  }
}

export const actions = {
  retrievePreferences (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/notification_preference'
    }).then(({ data }) => {
      context.commit('setPreferences', data.data.preferences)
      return data.data.preferences
    })
  },
  updatePreferences (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'put',
      url: '/api/notification_preference',
      data: context.state.preferences
    })
  },
  retrieveProposals (context, params) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/credential_proposal?offset=${params.offset}&limit=${params.limit}`
    }).then(({ data }) => {
      context.commit('setCredentialProposals', data.data)
      context.commit('setCount', {
        count: data.count,
        type: 'proposals'
      })
      return data.data
    })
  },
  deleteProposal (context, key) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'delete',
      url: '/api/credential_proposal',
      data: {
        proposal_id: key
      }
    })
  }
}

export default {
  state,
  mutations,
  actions
}
