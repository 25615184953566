export const abbrMapper = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AS: 'American Samoa',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming'
}

export const stateProvinceAbbrMapper = {
  ...abbrMapper,
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon'
}

export const cdsAbbrMapper = {
  AL: 'Alabama',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  MI: 'Michigan',
  MO: 'Missouri',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  OK: 'Oklahoma',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  UT: 'Utah',
  WY: 'Wyoming'
}

export const cdsStates = ['Alabama', 'Connecticut', 'District of Columbia', 'Delaware', 'Guam', 'Hawaii', 'Idaho',
  'Illinois', 'Indiana', 'Iowa', 'Louisiana', 'Maryland', 'Massachusetts', 'Michigan', 'Missouri', 'Nevada',
  'New Jersey', 'New Mexico', 'Oklahoma', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Utah', 'Wyoming']

export const cdsURLs = {
  AL: 'https://abme.igovsolution.net/online/User_Credentialer/User_CredentialerLogin.aspx',
  CT: 'https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx',
  DC: 'https://doh.force.com/ver/s/',
  DE: 'https://delpros.delaware.gov/OH_VerifyLicense',
  HI: 'https://ned.ehawaii.gov/renewal/search.html?execution=e2s1',
  IA: 'https://ibop.igovsolution.net/online/Lookups/Lookup_Individual.aspx',
  ID: 'https://idbop.mylicense.com/verification/',
  IL: 'https://online-dfpr.micropact.com/Lookup/LicenseLookup.aspx',
  IN: 'https://mylicense.in.gov/EVerification/Search.aspx',
  LA: 'https://secure.pharmacy.la.gov/Lookup/LicenseLookup.aspx',
  MA: 'https://madph.mylicense.com/verification/',
  MD: 'https://health.maryland.gov/ocsa/Pages/cdssearch.aspx',
  MI: 'https://aca3.accela.com/MILARA/GeneralProperty/PropertyLookUp.aspx?isLicensee=Y&TabName=APO',
  MO: 'https://healthapps.dhss.mo.gov/mohworxsearch/RegistrantSearch.aspx',
  NJ: 'https://newjersey.mylicense.com/Verification/Search.aspx',
  NM: 'https://www.rld.nm.gov/boards-and-commissions/individual-boards-and-commissions/pharmacy/pharmacy-look-up-a-license/',
  NV: 'https://online.nvbop.org/#/verifylicense',
  OK: 'https://obnddc.us.thentiacloud.net/webs/obnddc/register/',
  RI: 'https://healthri.mylicense.com/verification/',
  SC: 'https://apps.dhec.sc.gov/DrugControl/Licensing/home/verify',
  SD: 'https://apps.sd.gov/PH04ControlledSubstance',
  UT: 'https://secure.utah.gov/llv/search/index.html',
  WY: 'https://wybopprod.glsuite.us/GLSuiteWeb/Clients/WYBOP/Public/Verification/Search.aspx'
}

export const hasCDS = (state) => {
  return cdsStates.includes(abbrMapper[state])
}

export const stateMapper = Object.entries(abbrMapper).reduce((acc, [key, val]) => {
  acc[val] = key
  return acc
}, {})

export const stateAbbrs = Object.keys(abbrMapper).sort()

export const statesProvincesSorted = Object.keys(stateProvinceAbbrMapper).sort().reduce(
  (obj, key) => {
    obj[key] = stateProvinceAbbrMapper[key]
    return obj
  },
  {}
)

export const cdsStatesAbbrs = Object.keys(cdsAbbrMapper).sort()

export const states = Object.entries(abbrMapper).map(([key, value]) => {
  return value
}).sort()

export const getAbbr = (state) => {
  for (const n in stateMapper) {
    if (state.toLowerCase() === n.toLowerCase()) {
      return stateMapper[n]
    }
  }
}

export const stateFilters = (searchText) => {
  const allStates = Object.entries(abbrMapper).map(([key, value]) => {
    return { type: 'states', item: key, name: value }
  }).sort((a, b) => {
    if (a.name < b.name) {
      return -1
    } else if (a.name > b.name) {
      return 1
    }
    return 0
  })
  if (searchText === '') { return allStates }
  return allStates.filter((state) => {
    return state.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 || state.item.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
  })
}
