import Vue from 'vue'
import buefy from 'buefy'
import Maska from 'maska'
import VueApexCharts from 'vue-apexcharts'

// Local
import App from '@/App.vue'
import i18n from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'
import err from 'vue-plugin-error'
import credential from 'healthcare-credentials'
import placeholder from 'vue-plugin-placeholder'
import errorTracking from 'sentry-error-tracking'
import './vee-validate'

Vue.config.productionTip = false
// log the current version of the application
console.info(`issuer version ${process.env.VUE_APP_VERSION}`)
console.info(`git hash ${process.env.VUE_APP_GIT_HASH}`)

Vue.use(buefy, {
  defaultIconPack: 'feather',
  defaultFieldLabelPosition: 'outside',
  customIconPacks: {
    feather: {
      iconPrefix: 'icon-'
    }
  }
})

// tracks and persists errors
Vue.use(errorTracking, {
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_ENV,
  release: process.env.VUE_APP_VERSION,
  role: 'subscriber',
  router: router
})
// handles and reports errors for tracking
Vue.use(err, {
  store: store,
  i18n: i18n
})
Vue.use(credential)
Vue.use(placeholder)
Vue.use(Maska)

Vue.use(VueApexCharts)

Vue.component('ApexChart', VueApexCharts)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
