import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export const state = () => {
  return {
    hospitalAffiliations: [],
    hospitalDataByID: {},
    transformedAffiliations: []
  }
}

export const mutations = {
  setHospitalAffiliations (state, hospitalAffiliations) {
    state.hospitalAffiliations = hospitalAffiliations || []
  },
  setHospitalDataByID (state, hospitalDataByID) {
    state.hospitalDataByID = hospitalDataByID
  },
  transformAffiliations (state, hospitalDataByID) {
    const transformed = state.hospitalAffiliations
    for (let i = 0; i < transformed.length; i++) {
      if (transformed[i].hospital_id) {
        Object.assign(transformed[i], hospitalDataByID[transformed[i].hospital_id])
      }
    }
    state.transformedAffiliations = transformed
  }
}

export const actions = {
  retrieveInstitutionData (context, hosptialIDs) {
    const axios = createAuthInstance(context, createXSRFInstance())
    axios.get(`/api/hospital_search?hospital_ids=${hosptialIDs.join()}`).then(({ data }) => {
      const byID = {}
      for (const institution of data.data) {
        byID[institution.id] = institution
      }
      context.commit('setHospitalDataByID', byID)
      return byID
    }).catch((err) => {
      this.$err(err)
    })
  }
}

export const getters = {
  getHospitalIDs (state) {
    return state.hospitalAffiliations.filter(affiliation => !!affiliation.hospital_id).map(affiliation => affiliation.hospital_id)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
