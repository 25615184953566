import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

/**
 * Get Dimensions from the remote analytics endpoint
 * @param {*} context an application context from vuex and workforce intelligence
 * @param {string} url the remote url
 * @param {*} params the query parameters to use
 * @param {string} loadingKey the key to commit to when loading state changes
 * @param {string} dataKey the key to commit new data to when loading is successful
 * @returns {Promise<any>} promise
 */
export function getDimensions (context, url, params, loadingKey, dataKey = null) {
  const axios = createAuthInstance(context, createXSRFInstance())
  context.commit(loadingKey, true)
  return axios({
    method: 'get',
    url,
    params: params
  }).then(({ data }) => {
    context.commit(loadingKey, false)
    if (dataKey) {
      context.commit(dataKey, data)
    }
    return data
  }).catch(() => {
    context.commit(loadingKey, false)
  })
}

/**
 * Get Metrics from the remote analytics endpoint
 * @param {*} context an application context from vuex and workforce intelligence
 * @param {string} url the remote url
 * @param {string} loadingKey the key to commit to when loading state changes
 * @param {string} dataKey the key to commit new data to when loading is successful
 * @returns {Promise<any>} promise
 */
export function getMetrics (context, url, loadingKey, dataKey) {
  context.commit(loadingKey, true)
  const axios = createAuthInstance(context, createXSRFInstance())
  return axios({
    method: 'post',
    url,
    data: {
      filters: context.state.filters
    }
  }).then(({ data }) => {
    context.commit(loadingKey, false)
    context.commit(dataKey, data)
    return data
  }).catch(() => {
    context.commit(loadingKey, false)
  })
}

/**
 * Get detailed information from remote systems for a single clinician by NPI
 * @param {any} context application state context
 * @param {string} url remote api url
 * @param {string} loadingKey loading key to manipulate during load
 * @param {string} dataKey data key to store values on success
 * @returns {Promise<any>} promise
 */
export function getClinicianInfo (context, url, loadingKey, dataKey, params = null) {
  context.commit(loadingKey, true)
  const axios = createAuthInstance(context, createXSRFInstance())
  return axios({
    method: 'get',
    url,
    params
  }).then(({ data }) => {
    context.commit(loadingKey, false)
    context.commit(dataKey, data)
    return data
  }).catch(() => {
    context.commit(loadingKey, false)
  })
}

export function updateSearch (context, url, body = {}) {
  const axios = createAuthInstance(context, createXSRFInstance())
  return axios({
    method: 'patch',
    url,
    data: body
  })
}

export function deleteSearch (context, url) {
  const axios = createAuthInstance(context, createXSRFInstance())
  return axios({ method: 'delete', url })
}

/**
 * creates a search by POST`ing against the specified url with an attached body
 * @param {*} context state context
 * @param {*} url url to target for search creation
 * @param {*} body data to populate search initially with
 * @returns {Promise<any>} Axios Fetch promise response
 */
export function createSearch (context, url, body = {}) {
  const axios = createAuthInstance(context, createXSRFInstance())
  return axios({
    method: 'post',
    url,
    data: body
  })
}

/**
 * Get saved searches for the current user
 * @param {*} context vuex state context
 * @param {*} url url to fetch searches from
 * @returns {Promise<[Any]>}
 */
export function listSearches (context, url) {
  const axios = createAuthInstance(context, createXSRFInstance())
  context.commit('setSavedSearchesLoading', true)
  return axios({
    method: 'get',
    url
  }).then(i => {
    context.commit('setSavedSearchesLoading', false)
    return i
  }).catch(e => {
    context.commit('setSavedSearchesLoading', false)
    return e
  })
}
