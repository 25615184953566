/**
 * @typedef {import('../helpers/filters').WIState} WIState
 * @typedef {import('../helpers/filters').Filter} Filter
*/

import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'
import { createSearch, deleteSearch, getClinicianInfo, getDimensions, getMetrics, listSearches, updateSearch } from '../helpers/remoteFetchers'

/** @returns {WIState} */
export const state = () => {
  let localFilter = []
  const storedFilters = localStorage.getItem('filters')
  if (storedFilters === null || storedFilters === undefined) {
    localFilter = []
  } else {
    try {
      localFilter = JSON.parse(storedFilters)
    } catch (error) {
      if ((error instanceof SyntaxError)) {
        localFilter = []
        localStorage.setItem('filters', localFilter)
      } else {
        console.error(error)
      }
    }
  }
  return {
    specialties: [],
    stateLicenseData: [],
    loading: false,
    countLoading: false,
    searchLoading: false,
    reportLoading: false,
    reportExportLoading: false,
    clinicianDetailLoading: false,
    clinicianEnrollmentLoading: false,
    clinicianFacilitiesLoading: false,
    clinicianScoresLoading: false,
    clinicianSpecialtiesLoading: false,
    clinicianStatesLoading: false,
    clinicianDiagnosesLoading: false,
    savedSearchesLoading: false,
    totalClinicianCount: 0,
    providerDemos: [],
    providerDemosExport: [],
    filters: localFilter,
    filterHistory: [],
    stateAffiliationData: [],
    facilityAffiliationData: [],
    healthSystemData: [],
    marketData: [],
    relationshipData: [],
    clinicianTypeData: [],
    currentClinician: null,
    clinicianDetailData: [],
    clinicianEnrollmentData: [],
    clinicianFacilityData: [],
    clinicianScoresData: [],
    clinicianSpecialtiesData: [],
    clinicianStatesData: [],
    clinicianDiagnosesData: [],
    credentialData: [],
    savedSearches: []
  }
}

export const getters = {
  isSearchPageLoading (state) {
    return state.loading || state.countLoading
  },
  isReportLoading (state) {
    return state.reportLoading
  }
}

export const mutations = {
  setSpecialty (state, specialty) {
    state.specialties = specialty
  },
  setSearchLoading (state, loading) {
    state.searchLoading = loading
  },
  setReportLoading (state, loading) {
    state.reportLoading = loading
  },
  setReportExportLoading (state, loading) {
    state.reportExportLoading = loading
  },
  setClinicianDetailLoading (state, loading) {
    state.clinicianDetailLoading = loading
  },
  setClinicianEnrollmentLoading (state, loading) {
    state.clinicianEnrollmentLoading = loading
  },
  setClinicianFacilitiesLoading (state, loading) {
    state.clinicianFacilitiesLoading = loading
  },
  setClinicianScoresLoading (state, loading) {
    state.clinicianScoresLoading = loading
  },
  setClinicianSpecialtiesLoading (state, loading) {
    state.clinicianSpecialtiesLoading = loading
  },
  setClinicianStatesLoading (state, loading) {
    state.clinicianStatesLoading = loading
  },
  setClinicianDiagnosesLoading (state, loading) {
    state.clinicianDiagnosesLoading = loading
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setCountLoading (state, loading) {
    state.countLoading = loading
  },
  setTotalClinicianCount (state, count) {
    state.totalClinicianCount = count
  },
  setProviderDemos (state, demos) {
    state.providerDemos = demos
  },
  setProviderDemosExport (state, demos) {
    state.providerDemosExport = demos
  },
  /** reset all active filters to new filter array
   * @param {WIState["filters"]} filters
   * @param {WIState} state
  */
  setFilters (state, filters) {
    localStorage.setItem('filters', JSON.stringify(filters))
    state.filters = filters
  },
  clearFilters (state) {
    const empty = []
    if (state.filters.length !== 0) {
      state.filters = empty
      localStorage.setItem('filters', JSON.stringify(empty))
    }
  },
  /** add Filter to active filters
   *
   * NOTE: currently only adds to AND condition
   *
   * @param {Filter} filter
   * @param {WIState} state
    */
  addFilter (state, filter) {
    const currentFilters = state.filters
    // check for existing filter
    // definition of eq for filter is string equality by value
    // for keys of dimension, op, and val, where val equality is
    // case insensitive
    const itemFound = currentFilters.filter((f) => {
      const stringInsideFilter = JSON.stringify({
        dimension: f.dimension,
        op: f.op,
        val: f.val.length ? f.val : f.val.toUpperCase()
      })
      const stringCheckFilter = JSON.stringify(
        {
          dimension: filter.dimension,
          op: filter.op,
          val: filter.val.length ? filter.val : filter.val.toUpperCase()
        })
      return stringInsideFilter === stringCheckFilter
    })
    // only add if existing filter not found
    if (itemFound.length === 0) {
      state.filters = [...state.filters, filter]
      localStorage.setItem('filters', JSON.stringify([...state.filters]))
    }
  },
  /**
   * Append to filter history
   * @param {WIState} state
   * @param {Filter} filters
   */
  appendFilterHistory (state) {
    if (state.filterHistory.length > 100) {
      // performs a copy with the last element removed
      state.filterHistory = state.filterHistory.toSpliced(-1, 1)
    }
    // copies and adds a new element to end for filter history
    state.filterHistory = [...state.filterHistory, [new Date().toUTCString(), state.filters]]
  },
  setStateAffiliationData (state, data) {
    state.stateAffiliationData = data
  },
  setFacilityAffiliationData (state, data) {
    state.facilityAffiliationData = data
  },
  setHealthSystemData (state, data) {
    state.healthSystemData = data
  },
  setMarketData (state, data) {
    state.marketData = data
  },
  setClinicianTypesData (state, data) {
    state.clinicianTypeData = data
  },
  setRelationshipData (state, data) {
    state.relationshipData = data
  },
  setCurrentClinician (state, data) {
    state.currentClinician = data
    localStorage.setItem('currentClinician', data)
  },
  setClinicianDetailData (state, data) {
    state.clinicianDetailData = data
  },
  setClinicianEnrollmentData (state, data) {
    state.clinicianEnrollmentData = data
  },
  setClinicianFacilityData (state, data) {
    state.clinicianFacilityData = data
  },
  setClinicianScoresData (state, data) {
    state.clinicianScoresData = data
  },
  setClinicianSpecialtiesData (state, data) {
    state.clinicianSpecialtiesData = data
  },
  setClinicianStatesData (state, data) {
    state.clinicianStatesData = data
  },
  setClinicianDiagnosesData (state, data) {
    state.clinicianDiagnosesData = data
  },
  setCredentialData (state, data) {
    state.credentialData = data
  },
  setSavedSearches (state, data) {
    state.savedSearches = data
  },
  setSavedSearchesLoading (state, data) {
    state.savedSearchesLoading = data
  }
}

export const actions = {
  addFilter (context, filter) {
    context.commit('addFilter', filter)
    context.commit('appendFilterHistory')
  },
  setFilters (context, filters) {
    context.commit('setFilters', filters)
    context.commit('appendFilterHistory')
  },
  clearFilters (context) {
    context.commit('clearFilters')
    context.commit('appendFilterHistory')
  },
  getClinicianCount (context) {
    context.commit('setCountLoading', true)
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'post',
      url: '/api/v2/workforce_intelligence/npi_count',
      data: {
        filters: context.state.filters
      }
    }).then(({ data }) => {
      context.commit('setTotalClinicianCount', data.count)
      context.commit('setCountLoading', false)
    }).catch((_err) => {
      context.commit('setCountLoading', false)
    })
  },
  getProviderDemos: (context, { params }) => {
    const axios = createAuthInstance(context, createXSRFInstance())
    context.commit('setReportLoading', true)
    return axios({
      method: 'post',
      url: '/api/v2/workforce_intelligence/provider_demos',
      data: { ...params, reportFilters: context.state.filters }
    }).then(({ data }) => {
      context.commit('setReportLoading', false)
      context.commit('setProviderDemos', data.items)
      return data
    }).catch((_err) => {
      context.commit('setReportLoading', false)
    })
  },
  getProviderDemosExport: (context, { params }) => {
    const axios = createAuthInstance(context, createXSRFInstance())
    context.commit('setReportLoading', true)
    return axios({
      method: 'post',
      url: '/api/v2/workforce_intelligence/provider_demos',
      data: { ...params, reportFilters: context.state.filters }
    }).then(({ data }) => {
      context.commit('setReportLoading', false)
      context.commit('setProviderDemos', data.items)
      return data
    }).catch((_err) => {
      context.commit('setReportLoading', false)
    })
  },
  getFacilityAffiliationDimensions (
    context,
    { searchStr, page, pageSize } =
    { searchStr: '', page: 1, pageSize: 10 }) {
    const url = '/api/v2/workforce_intelligence/organization/dimensions'
    const params = { searchStr, page, page_size: pageSize }
    return getDimensions(context, url, params, 'setSearchLoading')
  },
  getHealthSystemDimensions (
    context,
    { searchStr, page, pageSize } =
    { searchStr: '', page: 1, pageSize: 10 }) {
    const url = '/api/v2/workforce_intelligence/health_system/dimensions'
    const params = { searchStr, page, page_size: pageSize }
    return getDimensions(context, url, params, 'setSearchLoading')
  },
  getMarketDimensions (
    context,
    { searchStr, page, pageSize } =
    { searchStr: '', page: 1, pageSize: 10 }) {
    const url = '/api/v2/workforce_intelligence/market/dimensions'
    const params = { searchStr, page, page_size: pageSize }
    return getDimensions(context, url, params, 'setSearchLoading')
  },
  getStateDimensions: (context, { searchStr }) => {
    const url = '/api/v2/workforce_intelligence/state/dimensions'
    const params = { searchStr: searchStr }
    return getDimensions(context, url, params, 'setSearchLoading')
  },
  getCredentialDimensions: (context, { searchStr }) => {
    const url = '/api/v2/workforce_intelligence/credential/dimensions'
    const params = { searchStr: searchStr }
    return getDimensions(context, url, params, 'setSearchLoading')
  },
  getSpecialtyDimensions (
    context,
    { searchStr, page, pageSize } =
    { searchStr: '', page: 1, pageSize: 10 }) {
    const url = '/api/v2/workforce_intelligence/specialty/dimensions'
    const params = { searchStr, page, page_size: pageSize }
    return getDimensions(context, url, params, 'setSearchLoading')
  },
  getRelationshipDimensions: (context, { searchStr }) => {
    context.commit('setSearchLoading', true)
    return new Promise((resolve, reject) => {
      resolve({
        data: [
          { relationship: 'Employed' },
          { relationship: 'Affiliated' },
          { relationship: 'Contracted' },
          { relationship: 'No Affiliation' }
        ].filter((match) => {
          return match.relationship.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase())
        })
      })
    }).then(({ data }) => {
      context.commit('setSearchLoading', false)
      return data
    }).catch(() => {
      context.commit('setSearchLoading', false)
    })
  },
  getClinicianTypeDimensions: (context, { searchStr }) => {
    context.commit('setSearchLoading', true)
    return new Promise((resolve, reject) => {
      resolve({
        data: [
          { type: 'Allopathic & Osteopathic Physicians' }
        ].filter((match) => {
          return match.type.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase())
        })
      })
    }).then(({ data }) => {
      context.commit('setSearchLoading', false)
      return data
    }).catch(() => {
      context.commit('setSearchLoading', false)
    })
  },
  getCliniciansByName: (context, { searchStr }) => {
    const url = '/api/v2/workforce_intelligence/clinician/name/dimensions'
    const params = { searchStr: searchStr }
    return getDimensions(context, url, params, 'setSearchLoading')
  },
  getSpecialtiesData (context) {
    const url = '/api/v2/workforce_intelligence/specialty/metrics'
    return getMetrics(context, url, 'setLoading', 'setSpecialty')
  },
  getStateAffiliationData (context) {
    const url = '/api/v2/workforce_intelligence/state/metrics'
    return getMetrics(context, url, 'setLoading', 'setStateAffiliationData')
  },
  getFacilityAffiliationData (context) {
    const url = '/api/v2/workforce_intelligence/organization/metrics'
    return getMetrics(context, url, 'setLoading', 'setFacilityAffiliationData')
  },
  getHealthSystemData (context) {
    const url = '/api/v2/workforce_intelligence/health_system/metrics'
    return getMetrics(context, url, 'setLoading', 'setHealthSystemData')
  },
  getMarketData (context) {
    const url = '/api/v2/workforce_intelligence/market/metrics'
    return getMetrics(context, url, 'setLoading', 'setMarketData')
  },
  getRelationshipData (context) {
    const url = '/api/v2/workforce_intelligence/relationship/metrics'
    return getMetrics(context, url, 'setLoading', 'setRelationshipData')
  },
  getCredentialData (context) {
    const url = '/api/v2/workforce_intelligence/credential/metrics'
    return getMetrics(context, url, 'setLoading', 'setCredentialData')
  },
  getClinicianTypesData (context) {
    const url = '/api/v2/workforce_intelligence/clinician_type/metrics'
    return getMetrics(context, url, 'setLoading', 'setClinicianTypesData')
  },
  getClinicianDetail (context, npi) {
    const url = `/api/v2/workforce_intelligence/clinician/${npi}`
    return getClinicianInfo(context, url, 'setClinicianDetailLoading', 'setClinicianDetailData')
  },
  getClinicianEnrollment (context, npi) {
    const url = `/api/v2/workforce_intelligence/clinician/${npi}/enrollment`
    return getClinicianInfo(context, url, 'setClinicianEnrollmentLoading', 'setClinicianEnrollmentData')
  },
  getClinicianFacilityAffiliations (context, npi) {
    const url = `/api/v2/workforce_intelligence/clinician/${npi}/facilities`
    return getClinicianInfo(context, url, 'setClinicianFacilitiesLoading', 'setClinicianFacilityData')
  },
  getClinicianScores (context, npi) {
    const url = `/api/v2/workforce_intelligence/clinician/${npi}/scores`
    return getClinicianInfo(context, url, 'setClinicianScoresLoading', 'setClinicianScoresData')
  },
  getClinicianSpecialties (context, npi) {
    const url = `/api/v2/workforce_intelligence/clinician/${npi}/specialties`
    return getClinicianInfo(context, url, 'setClinicianSpecialtiesLoading', 'setClinicianSpecialtiesData')
  },
  getClinicianStates (context, npi) {
    const url = `/api/v2/workforce_intelligence/clinician/${npi}/states`
    return getClinicianInfo(context, url, 'setClinicianStatesLoading', 'setClinicianStatesData')
  },
  getClinicianDiagnoses (context, { npi, year }) {
    const url = `/api/v2/workforce_intelligence/clinician/${npi}/diagnoses`
    const params = { year: year }
    return getClinicianInfo(context, url, 'setClinicianDiagnosesLoading', 'setClinicianDiagnosesData', params)
  },
  getSavedSearches (context) {
    const url = '/api/v2/workforce_intelligence/search'
    return listSearches(context, url)
  },
  deleteSavedSearch (context, id) {
    const url = `/api/v2/workforce_intelligence/search/${id}`
    return deleteSearch(context, url)
  },
  createSavedSearch (context, body) {
    const url = '/api/v2/workforce_intelligence/search'
    return createSearch(context, url, body)
  },
  updateSavedSearch (context, { id, name }) {
    const url = `/api/v2/workforce_intelligence/search/${id}`
    return updateSearch(context, url, { id, name })
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
