import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export const state = () => {
  return {
    job_id: '',
    export_id: '',
    reportConfigs: []
  }
}

export const mutations = {
  setIDs (state, data) {
    state.job_id = data.job_id
    state.export_id = data.export_id
  },
  setReportConfigs (state, data) {
    state.reportConfigs = data
  }
}

export const actions = {
  get (context, exportId) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/export_evidence/${exportId || context.state.export_id}`
    }).then(({ data }) => {
      return data.data
    })
  },
  getAllExports (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/export_evidence'
    }).then(({ data: { data } }) => {
      return data
    })
  },
  bulkAction (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'post',
      url: '/api/bulk_action',
      data: data
    }).then((data) => {
      return data.data
    })
  },
  create (context, theirDid) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'post',
      url: '/api/export_evidence',
      data: {
        their_did: theirDid
      }
    }).then(({ data }) => {
      context.commit('setIDs', data.data)
      return data
    })
  },
  caqh (context, providerID) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/caqh?npi=${providerID}&zkps=true&format=pdf`,
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    }).then(({ data }) => {
      return data
    })
  },
  getReportConfigs (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/v2/report_configs'
    }).then(({ data }) => {
      data.data.reports.sort((a, b) => {
        if (a.profile_name > b.profile_name) {
          return 1
        }
        if (a.profile_name < b.profile_name) {
          return -1
        }
        return 0
      })
      context.commit('setReportConfigs', data.data.reports)
    })
  },
  getReport (context, jobID) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/v2/reports/${jobID}`
    }).then(({ data }) => {
      console.log(data)
      return data
    })
  },
  createReport (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'post',
      url: '/api/v2/reports',
      data: data
    }).then((data) => {
      context.commit('setIDs', data.data)
      return data
    })
  }
}

export default {
  state,
  mutations,
  actions
}
