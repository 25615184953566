import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login.vue'
import Callback from '@/views/Callback.vue'
import store from '@/store'

Vue.use(Router)

// Route guard definition
function guardWorkforceIntel (to, from, next) {
  const hasAccess = store.getters['actor/hasAccess']('get_workforce_intel')
  if (!hasAccess) {
    return next('/dashboard')
  }

  return next()
}

const router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    name: '',
    component: Login,
    meta: {
      layout: 'blank'
    }
  }, {
    path: '/callback',
    name: 'callback',
    component: Callback,
    meta: {
      layout: 'blank'
    }
  }, {
    path: '/sso-error',
    name: 'SSO Error',
    component: () => import(/* webpackChunkName: "samlError" */ '@/views/SAMLError.vue'),
    meta: {
      layout: 'blank'
    }
  }, {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue')
  }, {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '@/views/Account.vue')
  }, {
    path: '/dashboard',
    name: 'Clinicians',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Subscriber/Dashboard.vue')
  }, {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "report" */ '@/views/Reports.vue'),
    beforeEnter: (to, from, next) => {
      const hasAccess = store.getters['actor/hasAccess']('get_evidence')
      if (!hasAccess) {
        return next('/dashboard')
      }

      return next()
    }
  }, {
    path: '/workforce_intelligence',
    component: () => import(/* webpackChunkName: "workforce_intelligence.router-view" */ '@/views/WorkforceIntelligence/RouterView.vue'),
    beforeEnter: guardWorkforceIntel,
    children: [
      {
        path: 'analyze',
        name: 'workforce_intelligence.analyze',
        component: () => import(/* webpackChunkName: "workforce_intelligence.analyze" */'@/views/WorkforceIntelligence/WiAnalyze.vue'),
        beforeEnter: guardWorkforceIntel
      },
      {
        path: 'clinician/:npi',
        name: 'workforce_intelligence.overview',
        component: () => import(/* webpackChunkName: "workforce_intelligence.overview" */'@/views/WorkforceIntelligence/WiOverview.vue')
      },
      {
        path: 'reports',
        name: 'workforce_intelligence.reports',
        component: () => import(/* webpackChunkName: "workforce_intelligence.reports" */'@/views/WorkforceIntelligence/WiReports.vue'),
        beforeEnter: guardWorkforceIntel
      },
      {
        path: '',
        name: 'workforce_intelligence.home',
        component: () => import(/* webpackChunkName: "workforce_intelligence.home" */ '@/views/WorkforceIntelligence/WiSearch.vue'),
        beforeEnter: guardWorkforceIntel
      }
    ]
  }, {
    path: '/presentations/:id/:tab?',
    name: 'Clinician Presentation',
    component: () => import(/* webpackChunkName: "presentation" */ '@/views/Subscriber/Presentation.vue')
  }, {
    path: '/invite',
    name: 'Invite Clinicians',
    component: () => import(/* webpackChunkName: "invite" */ '@/views/Subscriber/Invite.vue'),
    beforeEnter: (to, from, next) => {
      const hasAccess = store.getters['actor/hasAccess']
      if (!hasAccess('invite_holders')) {
        return next('/dashboard')
      }

      return next()
    }
  }, {
    path: '/redirect',
    name: 'Redirect',
    component: () => import(/* webpackChunkName: "redirect" */ '@/views/Redirect.vue'),
    meta: {
      layout: 'blank'
    }
  }, {
    path: '/expired',
    name: 'Expired',
    component: () => import(/* webpackChunkName: "expired" */ '@/views/Expired.vue'),
    meta: {
      layout: 'blank'
    }
  }, {
    path: '/timeout',
    name: 'Timeout',
    component: () => import(/* webpackChunkName: "timeout" */ '@/views/Timeout.vue'),
    meta: {
      layout: 'blank'
    }
  }]
})

router.beforeEach((to, from, next) => {
  const unprotectedRoutes = [
    '/',
    '/callback',
    '/expired',
    '/redirect',
    '/sso-error',
    '/timeout',
    '/work_verification'
  ]

  const unprotected = unprotectedRoutes.some(r => {
    const [, parent] = to.path.split('/')
    return r === `/${parent}`
  })

  document.title = 'Axuall' + ((to.name) ? ` - ${to.name}` : '')
  if (unprotected) {
    return next()
  }

  store.dispatch('auth/checkToken').then(() => {
    return store.dispatch('actor/retrieveCurrentActor')
  }).then(() => {
    return next()
  }).catch(() => {
    localStorage.setItem('loginRedirect', to.fullPath)
    store.dispatch('auth/logout')
  })
})

export default router
