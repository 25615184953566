import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export const state = () => {
  return {
    job: {}
  }
}

export const mutations = {
  setJob (state, job) {
    state.job = job
  }
}

export const actions = {
  get (context, jobID) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/job/${jobID}`
    }).then(({ data }) => {
      context.commit('setJob', data.data)
      return data.data
    })
  },
  revalidateCredential (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'post',
      url: '/api/revalidate_credential',
      data
    }).then(({ data }) => {
      return data.data
    })
  },
  retryCredential (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'post',
      url: '/api/retry_credential',
      data: {
        job_id: data.jobID
      }
    }).then(({ data }) => {
      return data.data
    })
  },
  revalidateCredentialForSubscriber (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'post',
      url: '/api/revalidate_credential',
      data: {
        axuall_proof_id: data.documentID,
        sponsor_id: data.subscriberID
      }
    }).then(({ data }) => {
      return data.data
    })
  }
}

export default {
  state,
  mutations,
  actions
}
