import Vue from 'vue'
import Vuex from 'vuex'

import actor from 'actor-store'
import address from './address'
import auth from './auth'
import checklist from './checklist'
import clinician from './clinician'
import clinicianDocs from './clinicianDocs'
import documents from './documents'
import exportStore from './export'
import invite from './invite'
import job from './job'
import notifications from './notifications'
import presentation from './presentation'
import professionalHistory from './professionalHistory'
import search from './search'
import selfAttested from './selfAttested'
import subscription from './subscription'
import tasks from './tasks'
import workforceIntel from './workforceIntel'

Vue.use(Vuex)

export const state = () => {
  return {}
}

const store = new Vuex.Store({
  state,
  modules: {
    auth: {
      namespaced: true,
      ...auth
    },
    actor: {
      namespaced: true,
      ...actor
    },
    address: {
      namespaced: true,
      ...address
    },
    clinician: {
      namespaced: true,
      ...clinician
    },
    presentation: {
      namespaced: true,
      ...presentation
    },
    professionalHistory: {
      namespaced: true,
      ...professionalHistory
    },
    search: {
      namespaced: true,
      ...search
    },
    selfAttested,
    subscription: {
      namespaced: true,
      ...subscription
    },
    tasks,
    notifications: {
      namespaced: true,
      ...notifications
    },
    documents: {
      namespaced: true,
      ...documents
    },
    export: {
      namespaced: true,
      ...exportStore
    },
    job: {
      namespaced: true,
      ...job
    },
    invite: {
      namespaced: true,
      ...invite
    },
    clinicianDocs: {
      namespaced: true,
      ...clinicianDocs
    },
    checklist: {
      namespaced: true,
      ...checklist
    },
    workforceIntel: {
      namespaced: true,
      ...workforceIntel
    }
  }
})

export default store
