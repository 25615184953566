<template>
  <section />
</template>

<script>
export default {
  name: 'Login',
  async created () {
    await this.$store.dispatch('auth/login')
  }
}
</script>
