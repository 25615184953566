import { compareDesc, parseISO } from 'date-fns'
import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

/**
 * Contains a map of rules we want to perform special transformations with
 * before committing them to the store
 * @param {String} key The key we want to fire the rule on
 * @param {Any} value The value we want to transform and return
 * @returns The transformed value based on the rules predicate
 */
function formatConfigValue (key, value) {
  const RULES = new Map([
    ['file_name', (val) => val.toLowerCase()]
  ])

  if (RULES.has(key)) {
    const fn = RULES.get(key)

    return fn(value)
  }

  return value
}

export default {
  namespaced: true,

  state: {
    clinicianTasks: {
      done: [],
      todo: []
    },
    currentTask: {
      name: '',
      holder_task_name: '',
      description: '',
      type: '',
      config: {}
    },
    queuedTasks: [],
    subscriberTasks: {
      active: [],
      archived: []
    },
    taskDocuments: [],
    taskSets: [],
    openSet: {
      id: '',
      tasks: []
    }
  },

  mutations: {
    addQueuedTask (state, taskToAdd) {
      if (taskToAdd) {
        // Check if this is a task set with multiple tasks to add
        if (taskToAdd.tasks) {
          taskToAdd.tasks.forEach(task => {
            // Check to ensure the task to add is not queued already
            if (!state.queuedTasks.find(queued => queued.id === task.id)) {
              state.queuedTasks.push(structuredClone(task))
            }
          })
        } else if (!state.queuedTasks.find(queued => queued.id === taskToAdd.id)) {
          state.queuedTasks.push(structuredClone(taskToAdd))
        }
      }
    },

    removeQueuedTask (state, index) {
      state.queuedTasks = [
        ...state.queuedTasks.slice(0, index),
        ...state.queuedTasks.slice(index + 1)
      ]
    },

    resetCurrentTask (state) {
      state.currentTask = {
        name: '',
        holder_task_name: '',
        description: '',
        type: '',
        config: {}
      }
    },

    resetQueue (state) {
      state.queuedTasks = []
    },

    setClinicianTasks (state, payload) {
      state.clinicianTasks = payload.reduce((acc, item) => {
        if (item.task_status === 'todo') {
          acc.todo.push(item)
        } else {
          acc.done.push(item)
        }

        return acc
      }, { done: [], todo: [] })

      state.clinicianTasks.done.sort((a, b) => {
        return compareDesc(parseISO(a.completed), parseISO(b.completed))
      })
    },

    setCurrentTask (state, payload) {
      state.currentTask = payload
    },

    setCurrentTaskConfig (state, payload) {
      state.currentTask.config = payload
    },

    setCurrentTaskByID (state, taskID) {
      const found = state.subscriberTasks.active.find(({ id }) => id === taskID)

      if (found) {
        // We need to rename task_type to type here
        // This is due to the API needing type for creating
        // but returning task_type for getting
        state.currentTask = {
          ...found,
          type: found.task_type
        }
      }
    },

    setSubscriberTasks (state, payload) {
      state.subscriberTasks = payload.reduce((acc, item) => {
        if (item.active) {
          acc.active.push(item)
        } else {
          acc.archived.push(item)
        }

        return acc
      }, { archived: [], active: [] })
    },

    setTaskDocuments (state, documents) {
      state.taskDocuments = documents
    },

    updateCurrentTaskConfig (state, { key, val }) {
      state.currentTask.config[key] = formatConfigValue(key, val)
    },

    updateCurrentTask (state, { key, val }) {
      state.currentTask[key] = val
    },

    setTaskSets (state, taskSets) {
      state.taskSets = Object.entries(taskSets).map(([key, val]) => {
        return {
          ...val,
          id: key
        }
      })
    },

    addTaskSet (state) {
      const newSet = {
        id: 'temp',
        name: '',
        tasks: []
      }
      state.taskSets.unshift(newSet)
      state.openSet = newSet
    },

    setOpenTaskSet (state, taskSet) {
      state.openSet = taskSet
    },

    setTaskSetProp (state, { key, val }) {
      state.openSet[key] = val
    },

    setRequiredTask (state, { id, required }) {
      const task = state.queuedTasks.find(task => task.id === id)

      if (task) {
        task.required = required
      }
    }
  },

  getters: {
    currentTaskConfigIsClean (state) {
      /**
       * This is a clean state getter, its job is to tell you
       * if the config has been dirtied
       * (someone started filling out the task form, or viewing a task)
       * or if its clean, the state can only be "clean" IF
       * the config object is completely empty or
       * the currentTask config only has the description key
       */

      if (Object.values(state.currentTask.config).length === 1 &&
      state.currentTask.config.description != null) {
        return true
      }

      return Object.values(state.currentTask.config).length === 0
    }
  },

  actions: {
    archiveSubscriberTask (context, id) {
      const axios = createAuthInstance(context, createXSRFInstance())

      return axios({
        method: 'DELETE',
        url: `/api/subscriber_task/${id}`
      })
    },

    getTaskDocuments (context, providerID) {
      const axios = createAuthInstance(context, createXSRFInstance())

      return axios({
        method: 'get',
        url: `/api/holder_task/${providerID}`
      }).then(({ data }) => {
        context.commit('setTaskDocuments', data.data)
      })
    },

    assignClinicianTask (context, data) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'POST',
        url: '/api/clinician_task',
        data: {
          provider_ids: data.provider_ids,
          task_ids: [...context.state.queuedTasks.map(task => task.id)]
        }
      }).then((response) => {
        // Will need to rework this to account for multiple provider ids
        // Right now we are expecting an array of only 1 id
        context.dispatch('fetchClinicianTasks', data.provider_ids[0])
        context.commit('resetQueue')
      })
    },

    assignRecipeTask (context, { taskID, recipes }) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'POST',
        url: `/api/recipe_task/${taskID}`,
        data: {
          recipes: recipes
        }
      })
    },

    fetchRecipeTask (context, taskID) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'GET',
        url: `/api/recipe_task/${taskID}`
      })
    },

    fetchTaskTypes (context) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'GET',
        url: '/api/task'
      })
    },

    fetchDocumentTypes (context) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'GET',
        url: '/api/document_types'
      })
    },

    cancelTask (context, data) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'DELETE',
        url: `/api/clinician_task/${data.taskId}`
      }).then((response) => {
        context.dispatch('fetchClinicianTasks', data.providerId)
        return response
      }).catch((err) => err)
    },

    createSubscriberTask (context) {
      const axios = createAuthInstance(context, createXSRFInstance())

      return axios({
        method: 'POST',
        url: '/api/subscriber_task',
        data: context.state.currentTask
      })
    },

    updateSubscriberTask (context, taskId) {
      const payload = {
        name: context.state.currentTask.name,
        description: context.state.currentTask.description,
        holder_task_name: context.state.currentTask.holder_task_name,
        config: context.state.currentTask.config,
        type: context.state.currentTask.type
      }
      const axios = createAuthInstance(context, createXSRFInstance())

      return axios({
        method: 'PUT',
        url: `/api/subscriber_task/${taskId}`,
        data: payload
      })
    },

    fetchClinicianTasks (context, id) {
      if (!context.rootGetters['actor/hasAccess']('get_clinician_tasks')) {
        return
      }

      const axios = createAuthInstance(context, createXSRFInstance())

      return axios({
        method: 'GET',
        url: `/api/clinician_task?provider_id=${id}`
      })
        .then(({ data: { data } }) => {
          data.sort((a, b) => {
            if (a.name > b.name) {
              return 1
            }

            if (a.name < b.name) {
              return -1
            }

            return 0
          })
          context.commit('setClinicianTasks', data)
        })
    },

    downloadClinicianTask (context, id) {
      const axios = createAuthInstance(context, createXSRFInstance())

      return axios({
        method: 'GET',
        url: `/api/clinician_task/${id}?action=download`
      }).then(({ data }) => {
        return data.data
      })
    },

    downloadQuestionPDF (context, id) {
      const axios = createAuthInstance(context, createXSRFInstance())

      return axios({
        method: 'GET',
        url: `/api/clinician_task/${id}?action=question_pdf`
      }).then(({ data }) => {
        return data.data
      })
    },

    fetchSubscriberTasks (context) {
      const axios = createAuthInstance(context, createXSRFInstance())

      return axios({
        method: 'GET',
        url: '/api/subscriber_task'
      })
        .then(({ data: { data } }) => {
          data.sort((a, b) => {
            if (a.name > b.name) {
              return 1
            }

            if (a.name < b.name) {
              return -1
            }

            return 0
          })
          context.commit('setSubscriberTasks', data)
        })
    },

    fetchTaskSets (context) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'GET',
        url: '/api/task_set'
      }).then(({ data }) => {
        context.commit('setTaskSets', data.data)
      })
    },

    createTaskSet (context, taskSet) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'POST',
        url: '/api/task_set',
        data: {
          name: taskSet.name.trim(),
          task_ids: taskSet.tasks.map(t => t.id)
        }
      })
    },

    updateTaskSet (context, taskSet) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'PUT',
        url: `/api/task_set/${taskSet.id}`,
        data: {
          name: taskSet.name.trim(),
          task_ids: taskSet.tasks.map(t => t.id)
        }
      })
    },

    deleteTaskSet (context, id) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'DELETE',
        url: `/api/task_set/${id}`
      })
    }
  }
}
