import { configure, extend, setInteractionMode } from 'vee-validate'
import { digits, email, required, regex } from 'vee-validate/dist/rules'
import i18n from '@/plugins/i18n'
import { isAfter, isEqual } from 'date-fns'

setInteractionMode('eager')

export const defaultMessageHandler = (i18n) => (field, values) => {
  // override the field name.
  values._field_ = i18n.t(`fields.${field}`)

  return i18n.t(`validation.${values._rule_}`, values)
}

export const defaultMessageHandlerNew = (i18n) => (field, values) => {
  return i18n.t(`validation.${values._rule_}`, values)
}

configure({
  defaultMessage: defaultMessageHandlerNew(i18n)
})

extend('regex', regex)
extend('file-name', {
  validate (value) {
    return /^[0-9a-zA-Z-_]+$/.test(value)
  },
  message: i18n.t('validation.file-name')
})
extend('required', required)
extend('date_format', {
  validate (value) {
    const month = value.split('/')[0]
    return /^\d{2}\/\d{4}$/.test(value) && parseInt(month, 10) >= 1 && parseInt(month, 10) <= 12 && month !== '00'
  },
  message: i18n.t('validation.date')
})
extend('email', email)
extend('npi', digits)
extend('ncsbn', digits)
extend('phone', {
  params: ['reg'],
  validate (value, { reg }) {
    const pattern = reg || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

    return pattern.test(value)
  }
})
extend('antiStartDate', {
  params: ['dateA', 'dateB'],
  validate: (value, { dateA, dateB }) => {
    if (isEqual(dateA, dateB)) return true
    return isAfter(dateA, dateB)
  },
  message: i18n.t('validation.anticipated-start-date')
})
extend('emailInUse', {
  params: ['check'],
  validate: async (_, { check }) => {
    return !(await check())
  },
  message: i18n.t('validation.email-in-use')
})

extend('validNPIForDegreeType', {
  params: ['check'],
  validate: (_, { check }) => {
    return check()
  },
  message: i18n.t('validation.invalid-npi-for-degree')
})

extend('validNCSBNForDegreeType', {
  params: ['check'],
  validate: (_, { check }) => {
    return check()
  },
  message: i18n.t('validation.invalid-ncsbn-for-degree')
})

extend('validDegreeType', {
  params: ['check'],
  validate: (_, { check }) => {
    return check()
  },
  message: i18n.t('validation.invalid-degree-type')
})
