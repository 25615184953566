import axios from 'axios'

export const createAuthInstance = (context, instance) => {
  instance = instance || axios.create()
  instance.interceptors.request.use(async (config) => {
    try {
      const accessToken = await context.dispatch('auth/retrieveAccessToken', null, { root: true })
      const actorID = await context.dispatch('actor/retrieveCurrentActor', null, { root: true })
      Object.assign(config.headers, {
        Authorization: `Bearer ${accessToken}`,
        'X-Axuall-Actor': actorID
      })
      return config
    } catch (err) {
      return Promise.reject(err)
    }
  }, (err) => {
    return Promise.reject(err)
  })
  return instance
}

export const createXSRFInstance = (instance) => {
  instance = instance || axios.create()
  instance.interceptors.request.use(async (config) => {
    const regex = /\b_xsrf=([^;]*)\b/g
    const [, xsrf] = regex.exec(document.cookie)
    const verbs = ['post', 'put', 'patch', 'delete']
    if ((verbs.indexOf(config.method) >= 0) && xsrf) {
      config.headers['X-XSRFToken'] = xsrf
    }
    return config
  })
  return instance
}

export default {
  createAuthInstance,
  createXSRFInstance
}
