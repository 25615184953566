import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export const state = () => {
  return {}
}

export const mutations = {}

export const actions = {
  autocomplete (context, { query, type }) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/address?q=${query}&type=${type}`
    }).then(({ data }) => {
      return data.data
    })
  },
  training_autocomplete (context, { query, type, clinicianType }) {
    const axios = createAuthInstance(context, createXSRFInstance())
    if (!type) type = 'residency'
    return axios({
      method: 'get',
      url: `/api/physician_training?q=${query}&training_type=${type}&clinician_type=${clinicianType}`
    }).then(({ data }) => {
      return data.data
    })
  },
  detail (context, { query, fields }) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/address_detail',
      params: {
        q: query,
        f: fields
      }
    }).then(({ data }) => {
      return data.data
    })
  }
}

export default {
  state,
  mutations,
  actions
}
