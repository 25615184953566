import { Auth0Client } from '@auth0/auth0-spa-js'
import axios from 'axios'
import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'
import { addSeconds, isAfter } from 'date-fns'

export const auth0 = new Auth0Client({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  authorizationParams: {
    redirect_uri: `${window.location.origin}/callback`,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    scope: 'openid profile email user_metadata offline_access'
  }
})

export const state = () => {
  return {
    profile: {},
    accessToken: '',
    accessTokenExpiry: 0,
    loginError: null
  }
}

export const mutations = {
  setToken (state, tokenResponse) {
    state.accessToken = tokenResponse.access_token
    state.accessTokenExpiry = addSeconds(new Date(), tokenResponse.expires_in)
  },
  setUser (state, user) {
    state.profile = helpers.formatProfileData(user)
  },
  setLoginError (state, err) {
    state.loginError = err
  },
  setPhone (state, phone) {
    if (!state.profile.user_metadata) {
      state.profile.user_metadata = {}
    }
    state.profile.user_metadata.phone = phone
  }
}

export const actions = {
  login () {
    return auth0.loginWithRedirect()
  },
  logout (_, redirect = false) {
    return auth0.logout({
      logoutParams: {
        returnTo: (redirect) ? `${window.location.origin}/redirect` : window.location.origin
      }
    })
  },
  async handleAuthentication ({ commit }) {
    await auth0.handleRedirectCallback()
    const user = await auth0.getUser()

    commit('setUser', user)
  },
  async retrieveUserProfile ({ commit }) {
    const isAuthed = await auth0.isAuthenticated()

    if (isAuthed) {
      const tokenResponse = await auth0.getTokenSilently({ detailedResponse: true, cacheMode: 'off' })

      commit('setToken', tokenResponse)

      const user = await auth0.getUser()

      commit('setUser', user)
    }
  },
  updateProfile (context, formData) {
    return context.dispatch('retrieveAccessToken').then((accessToken) => {
      const [, xsrf] = document.cookie.match('\\b_xsrf=([^;]*)\\b')
      return axios({
        method: 'patch',
        url: '/api/person',
        data: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
          'X-XSRFToken': xsrf
        }
      })
    })
  },
  checkToken ({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.accessToken &&
        isAfter(state.accessTokenExpiry, Date.now())) {
        return resolve(state.accessToken)
      }

      auth0.getTokenSilently({ detailedResponse: true }).then(tokenResponse => {
        commit('setToken', tokenResponse)
        resolve(tokenResponse.access_token)
      }).catch(err => {
        this._vm.$err(err, false)
        reject(err)
      })
    })
  },
  retrieveAccessToken ({ dispatch }) {
    return dispatch('checkToken').then(token => {
      return token
    }).catch(e => {
      dispatch('logout')
    })
  },
  getXSRFToken (context) {
    return axios({
      method: 'get',
      url: '/api/xsrf',
      timeout: 4000
    }).then(response => {
      context.commit('setUserHash', response.user_hash)
    })
  },
  expiredPassword (context, email) {
    return context.dispatch('sendResetPasswordEmail', email).then(() => {
      auth0.logout({
        logoutParams: {
          returnTo: `${window.location.origin}/expired`
        }
      })
    })
  },
  sendResetPasswordEmail (context, userEmail) {
    return axios({
      method: 'post',
      url: `https://${process.env.VUE_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
      data: {
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        connection: process.env.VUE_APP_AUTH0_CONNECTION,
        email: userEmail || context.state.profile.email
      }
    }).then(response => response.data)
  },
  trackUser (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'post',
      url: '/api/user_tracking',
      data: {
        id: context.state.profile.sub
      }
    }).then(response => response.data)
  },
  userLink ({ dispatch }) {
    return dispatch('retrieveAccessToken').then((authToken) => {
      if (authToken) {
        return axios({
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          method: 'post',
          url: '/api/user_link'
        }).then(response => response.data)
      }
    })
  },
  userLinkError () {
    auth0.logout({
      logoutParams: {
        returnTo: `${window.location.origin}/sso-error`
      }
    })
  }
}

export const helpers = {
  formatProfileData (profile) {
    const formatted = {}
    const domain = 'https://axuall.com/'
    for (const n in profile) {
      formatted[n.replace(domain, '')] = profile[n]
    }
    return formatted
  }
}

export const getters = {
  profile (state) {
    return state.profile
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  helpers
}
