import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export const state = () => {
  return {
    documents: [],
    transformed: []
  }
}

export const mutations = {
  setDocuments (state, documents) {
    state.documents = documents
  },
  transformedDocuments (state, documents) {
    state.transformed = documents
  }
}

export const actions = {
  getDocument (context, { documentID, theirDid }) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/subscriber_document/${documentID}`,
      params: {
        their_did: theirDid
      }
    }).then((data) => {
      return data.data
    })
  },
  getHolderDocument (context, { documentID, providerID }) {
    const axios = createAuthInstance(context, createXSRFInstance())
    const url = providerID ? `/api/document/${documentID}?provider_id=${providerID}` : `/api/document/${documentID}`

    return axios({
      method: 'get',
      url
    }).then((data) => {
      return data.data
    })
  },
  uploadDocument (context, document) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'post',
      url: '/api/document',
      data: document,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((data) => {
      return data.data
    })
  }
}

export default {
  state,
  mutations,
  actions
}
