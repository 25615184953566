import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export const state = () => {
  return {
    subscriptions: [],
    subscriberGroups: [],
    exportableClinicians: [],
    facilities: [],
    labels: [],
    applicationReqs: [],
    dashboard: {
      initialized: false,
      loading: false,
      // Search
      searchTerm: '',
      selectedFacilities: [],
      selectedLabels: [],
      selectedProviderTypes: [],
      selectedSpecialties: [],
      selectedStates: [],
      selectedStatuses: [],
      showMyClinicians: 'true',
      // Pagination
      currentPage: 1,
      pageLimit: 50,
      pageOffset: 0,
      // Sorting
      sortBy: 'status_updated',
      sortDir: 'desc',
      // Selections
      allChecked: false,
      checkedRows: []
    }
  }
}

export const mutations = {
  setSubscriptions (state, data) {
    state.subscriptions = data
  },
  setSubscriberGroups (state, data) {
    state.subscriberGroups = data
  },
  setLabels (state, data) {
    state.labels = data.data.labels.sort((a, b) => {
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
      return 0
    })
  },
  setFacilities (state, facilities) {
    state.facilities = facilities.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
  },
  setLoading (state, loading) {
    state.dashboard.loading = loading
  },
  setApplicationReqs (state, data) {
    state.applicationReqs = data
  },
  setExportableClinicians (state, data) {
    state.exportableClinicians = data
  }
}

export const getters = {
  subscriptionTotal (state) {
    return state.subscriptions.length ? state.subscriptions[0].full_count : 0
  }
}

export const actions = {
  retrieveSubscriptions (context, params) {
    for (const n in params) {
      if (params[n] === '') {
        delete params[n]
      }
    }
    const axios = createAuthInstance(context, createXSRFInstance())
    context.commit('setLoading', true)
    return axios({
      method: 'get',
      url: '/api/subscribed_holders',
      params
    }).then(({ data }) => {
      context.commit('setSubscriptions', data.data)
      return data.data
    }).finally(() => context.commit('setLoading', false))
  },
  retrieveSubscriberGroups (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/subscriber_groups'
    }).then(({ data }) => {
      context.commit('setSubscriberGroups', data.data)
      return data.data
    })
  },
  getFacilities (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/v2/facility_list'
    }).then(({ data }) => {
      context.commit('setFacilities', data.data.facilities)
      return data.data.facilities
    })
  },
  getExportableClinicians (context, params) {
    for (const n in params) {
      if (params[n] === '') {
        delete params[n]
      }
    }
    params.statuses = 'automated_share|presentation'
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/clinician_list',
      params
    }).then(({ data }) => {
      context.commit('setExportableClinicians', data.data)
      return data.data
    })
  },
  getAllClinicians (context, params) {
    for (const n in params) {
      if (params[n] === '') {
        delete params[n]
      }
    }
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/subscribed_holders',
      params
    }).then(({ data }) => {
      return data.data
    })
  },
  getLabels (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/label'
    }).then((data) => {
      context.commit('setLabels', data.data)
      return data.data
    })
  },
  updateClinicianLabels (context, params) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: params.method,
      url: '/api/clinician_label',
      data: {
        label_ids: params.label_ids,
        provider_ids: params.provider_ids
      }
    }).then((data) => {
      return data.data
    })
  },
  downloadSubscriptions (context, params) {
    for (const n in params) {
      if (params[n] === '') {
        delete params[n]
      }
    }
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/subscribed_holders/excel',
      headers: { Accept: 'application/vnd.ms-excel' },
      responseType: 'blob',
      params: params
    }).then(function (response) {
      const Axuall = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      if (window.navigator && window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(Axuall, 'clinicians.xlsx')
      } else {
        const url = window.URL.createObjectURL(Axuall)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1])
        document.body.appendChild(link)
        link.click()
      }
    })
  },
  updateStatus (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    context.commit('setLoading', true)
    return axios({
      method: 'patch',
      url: '/api/presentations',
      data: {
        reviewed: data.reviewed,
        their_did: data.theirDid,
        comments: data.comments
      }
    }).then(({ data }) => {
      return data.data
    }).finally(() => context.commit('setLoading', false))
  },
  retrieveApplicationReqs (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/application_requirements'
    }).then(({ data }) => {
      context.commit('setApplicationReqs', data.data)
      return data.data
    })
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
