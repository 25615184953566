export default {
  install (Vue, config) {
    Vue.prototype.$err = function (err, notify = true) {
      console.error(err)
      // log the error on the tracking platform
      this.$logException(err)
      const messages = config.i18n.messages[config.i18n.locale]
      let code = 'internal-error'
      if (err.response && err.response.data && err.response.data.error && err.response.data.error.code) {
        code = err.response.data.error.code
      }
      if (notify) {
        this.$buefy.snackbar.open({
          message: messages[code],
          position: 'is-top-right',
          type: 'is-white has-close',
          indefinite: (code === 'not-authenticated'),
          actionText: '⨉',
          'pause-on-hover': true,
          duration: 5000,
          onAction: () => {
            if (code === 'not-authenticated') {
              config.store.commit('auth/clearSession')
              window.location.href = '/'
            }
          }
        })
      }
    }
  }
}
