<template>
  <b-loading :active="true" />
</template>

<script>
export default {
  computed: {
    isPasswordExpired () {
      const errorDescription = this.$route.query.error_description ?? ''
      return errorDescription.toLowerCase().includes('password expired')
    }
  },
  async created () {
    try {
      // Handle password expiration
      if (this.isPasswordExpired) {
        const email = this.$route.query.error_description.toLowerCase().split('email: ')[1]
        await this.$store.dispatch('auth/expiredPassword', email)
        return
      }

      // Handle authentication
      await this.$store.dispatch('auth/handleAuthentication')

      // Retrieve the user's actors
      try {
        await this.$store.dispatch('auth/userLink')
      } catch {
        await this.$store.dispatch('auth/userLinkError')
        return
      }
      await this.$store.dispatch('actor/retrieveCurrentActor')

      // Attach user data to the logging platform
      const { name, email, sub } = this.$store.getters['auth/profile']
      this.$attachSessionData({ name, email, id: sub })

      const subscriberGroup = this.$store.getters['actor/currentSubscriberGroup']
      if (window.heap) {
        window.heap.identify(sub.split('|')[1])
        window.heap.addUserProperties({
          name,
          email,
          subscriber: subscriberGroup
        })
      }

      if (window.Cohere) {
        window.Cohere.identify(sub, {
          displayName: `${name} (${subscriberGroup} Admin)`,
          email
        })
      }

      try { await this.$store.dispatch('auth/trackUser') } catch { }

      // Verify the user has appropriate access
      const hasAccess = this.$store.getters['actor/hasAccess']('search_dids')
      if (!hasAccess) {
        return this.$store.dispatch('auth/logout', true)
      }

      // Route the user appropriately
      const loginRedirect = localStorage.getItem('loginRedirect')
      if (loginRedirect) {
        localStorage.removeItem('loginRedirect')
        this.$router.push(loginRedirect)
        return
      }
      this.$router.push('/dashboard')
    } catch (e) {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>
