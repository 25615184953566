import { render, staticRenderFns } from "./WorkTraining.vue?vue&type=template&id=1fe6950b"
import script from "./WorkTraining.vue?vue&type=script&lang=js"
export * from "./WorkTraining.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../frontend_admin/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports