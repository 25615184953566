import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export const categories = ['credentials', 'attested_requirements']

export const state = () => {
  return {
    app_req_name: '',
    ecfmg_number: '',
    fsmb_present: false,
    graduate_attested_none: false,
    nursys_present: false,
    review_date: null,
    credentials: {
      aha_cert: [],
      axuall_id: [],
      board_certs: [],
      cds: [],
      dea: [],
      education: [],
      equifax: [],
      npi: [],
      ongoing_monitoring: [],
      state_licenses: [],
      training: []
    },
    attested_requirements: {
      disclosure_questions: [],
      documents: []
    },
    app_config: {
      professional_history: {
        work_history: [],
        peer_references: [],
        affiliations: [],
        certificates_of_insurance: 0
      }
    },
    activeTab: 'all',
    annotations: []
  }
}

const defaultState = state()

export const mutations = {
  set (state, checklist) {
    for (const category in checklist) {
      state[category] = checklist[category]
    }
  },
  set_annotations (state, annotations) {
    state.annotations = annotations
  },
  reset (state) {
    state = defaultState
  },
  activeTab (state, activeTab) {
    state.activeTab = activeTab
  }
}

export const getters = {
  total (state) {
    /*
      This logic is currently innaccurate.
    */
    let total = 0
    categories.forEach(c => {
      Object.keys(state[c]).forEach(s => {
        if (Array.isArray(state[c][s])) total += state[c][s].length
      })
    })
    return total
  },
  successful (state) {
    let successful = 0
    categories.forEach(c => {
      Object.keys(state[c]).forEach(s => {
        if (Array.isArray(state[c][s])) {
          state[c][s].forEach(i => {
            if (!i.failed) successful++
          })
        }
      })
    })
    return successful
  },
  missing (state) {
    /*
      This logic is currently innaccurate.
    */
    let missing = 0
    categories.forEach(c => {
      Object.keys(state[c]).forEach(s => {
        if (Array.isArray(state[c][s])) {
          state[c][s].forEach(i => {
            if (i.failed) missing++
          })
        }
      })
    })
    return missing
  },
  filtered (state) {
    if (state.activeTab === 'missing') {
      const results = {
        app_config: state.app_config
      }
      categories.forEach(c => {
        if (!results[c]) results[c] = {}
        Object.entries(state[c]).forEach(([key, val]) => {
          if (Array.isArray(val)) {
            const missing = val.filter(v => v.failed)
            if (missing.length) results[c][key] = missing
          }
        })
      })
      return results
    }
    return {
      credentials: state.credentials,
      attested_requirements: state.attested_requirements,
      app_config: state.app_config,
      tasks: state.tasks,
      annotations: state.annotations
    }
  }
}

export const actions = {
  get (context, theirDid) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/checklist_v2?their_did=${theirDid}`
    }).then(({ data }) => {
      context.commit('set', data.data)
    })
  },
  getAnnotations (context, theirDid) {
    const axios = createAuthInstance(context, createXSRFInstance())

    return axios({
      method: 'get',
      url: `/api/v2/subscriptions/${theirDid}/annotations`
    }).then(({ data }) => {
      context.commit('set_annotations', data.results)
      return data
    })
  },
  downloadFSMBSummary (context, npi) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return context.dispatch('auth/retrieveAccessToken', null, { root: true }).then((accessToken) => {
      return axios({
        method: 'get',
        url: `/api/v2/fsmb/artifact?npi=${npi}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        responseType: 'blob'
      }).then((response) => {
        return response
      })
    })
  },
  downloadNursysSummary (context, ncsbn) {
    if (!ncsbn) throw new Error('NCSBN is undefined')
    const axios = createAuthInstance(context, createXSRFInstance())
    return context.dispatch('auth/retrieveAccessToken', null, { root: true }).then((accessToken) => {
      return axios({
        method: 'get',
        url: `/api/nursys/artifact?ncsbn_id=${ncsbn}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        responseType: 'blob'
      }).then((response) => {
        return response
      })
    })
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
