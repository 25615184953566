import { Mask } from 'loosely'

export default {
  install (Vue) {
    const dict = {}
    Vue.prototype.$placeholder = (regex) => {
      if (!regex) {
        return ''
      }
      if (dict[regex]) {
        return dict[regex]
      }
      const mask = new Mask(regex)
      dict[regex] = mask.sample()
      return dict[regex]
    }
  }
}
