import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export const state = () => {
  return {
    appReqs: [],
    credSet: '',
    facilities: [],
    inviteID: '',
    profile: null,
    selectedAppReq: null,
    availableDegreeTypes: []
  }
}

export const mutations = {
  setFacilities (state, facilities) {
    state.facilities = facilities.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
  },
  setAppReqs (state, appReqs) {
    state.appReqs = appReqs.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
  },
  setAppReq (state, appReq) {
    state.selectedAppReq = appReq
    state.credSet = appReq.cred_set_name.split('-')[0]
  },
  setInviteID (state, inviteID) {
    state.inviteID = inviteID
  },
  setProfile (state, profile) {
    state.profile = profile
  },
  setAvailableDegreeTypes (state, types) {
    state.availableDegreeTypes = types
  }
}

export const actions = {
  getFacilities (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/v2/facility_list'
    }).then(({ data }) => {
      context.commit('setFacilities', data.data.facilities)
    })
  },
  checkEmail (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())

    return axios({
      method: 'post',
      url: '/api/email',
      data
    })
      .then(({ data: { data: { user_found: userFound } } }) => {
        return userFound
      })
  },
  getAppReqs (context) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: '/api/v2/app_req_list'
    }).then(({ data }) => {
      context.commit('setAppReqs', data.data.app_reqs)
    })
  },
  fetchAppReq (context, id) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/application_requirement/${id}`
    }).then(({ data }) => {
      context.commit('setAppReq', data.data)
    })
  },
  create (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    const fd = new FormData()

    data.attachments.forEach(({ name, file }) => {
      fd.append(`${name}`, file)
    })

    if (data.resume && data.resume.file) {
      fd.append('resume', data.resume.file)
    }

    const compiledData = {
      subscriber_list: data.selectedFacilities,
      app_req_id: data.appReqID
    }
    if (data.invite?.ncsbn) {
      compiledData.ncsbn = data.invite.ncsbn
    } else {
      compiledData.npi = data.invite.npi
    }

    fd.append('data', JSON.stringify(compiledData))

    return axios({
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      url: '/api/v2/invite',
      data: fd
    }).then(({ data }) => {
      context.commit('setInviteID', data.data.invite_id)
    })
  },
  send (context, { data }) {
    const axios = createAuthInstance(context, createXSRFInstance())

    return axios({
      method: 'post',
      url: '/api/v2/send_invite',
      data
    })
  },
  sendReminder (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'post',
      url: '/api/send_reminder',
      data: {
        provider_id: data.provider_id,
        cc_emails: data.cc_emails
      }
    })
  },
  cancelInvite (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'put',
      url: `/api/holder_subscriptions/${data.provider_id}`
    })
  },
  editEmail (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'put',
      url: `/api/subscriber_provider_info/${data.provider_id}`,
      data: {
        email: data.updatedEmail
      }
    })
  },
  fetchProfile (context, providerId) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/provider_profile/${providerId}`
    }).then(({ data }) => {
      context.commit('setProfile', data.data)
    })
  },
  fetchAvailableDegreeTypes (context, appReqId) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/provider_type/filtered?app_req_id=${appReqId}`
    }).then(({ data }) => {
      context.commit('setAvailableDegreeTypes', data.data)
    })
  },
  clearAvailableDegreeTypes (context) {
    context.commit('setAvailableDegreeTypes', [])
  }
}

export default {
  state,
  mutations,
  actions
}
