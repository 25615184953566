
import axios from 'axios'
import debounce from 'debounce-promise'

export const state = () => {
  return {
    actors: {},
    currentActor: '',
    previousActor: ''
  }
}

export const mutations = {
  setActors (state, actors) {
    state.actors = actors
    const localActor = window.localStorage.getItem('currentActor')
    const actorList = Object.keys(actors)
    if (actorList.includes(localActor)) {
      state.currentActor = localActor
      return
    }
    const currentActor = actorList[0]
    if (currentActor && state.currentActor === '') {
      window.localStorage.setItem('currentActor', currentActor)
      state.currentActor = currentActor
    }
  },
  setCurrentActor (state, currentActor) {
    window.localStorage.setItem('currentActor', currentActor)
    state.currentActor = currentActor
  },
  setPreviousActor (state, actor) {
    window.localStorage.setItem('previousActor', actor)
    state.previousActor = actor
  }
}

export const actions = {
  retrieveCurrentActor: debounce(({ state, dispatch, commit }, forceRefresh = false) => {
    return new Promise((resolve, reject) => {
      if (state.currentActor && !forceRefresh) {
        return resolve(state.currentActor)
      }
      dispatch('auth/retrieveAccessToken', null, { root: true }).then((authToken) => {
        if (authToken) {
          axios.get('/api/actor', {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }).then(({ data }) => {
            const actors = data.data.actors
            commit('setActors', actors)
            resolve(state.currentActor)
          }).catch(reject)
        }
      })
    })
  }, 250)
}

export const getters = {
  hasAccess (state) {
    return (action) => {
      if (!state.actors || !state.currentActor) return false
      if (state.actors[state.currentActor].access_level === 'super_user') return true
      return state.actors[state.currentActor].actions.includes(action)
    }
  },
  getChatHash (state) {
    return state.actors[state.currentActor].chat_hash
  },
  getCurrentActorSettings (state) {
    if (state.actors[state.currentActor]) {
      return state.actors[state.currentActor].settings
    }
    return {}
  },
  currentActorName (state) {
    return state.actors[state.currentActor] ? state.actors[state.currentActor].name : ''
  },
  currentSubscriberGroup (state) {
    return state.actors[state.currentActor] ? state.actors[state.currentActor].subscriber_group_name : ''
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
