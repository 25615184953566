import * as Sentry from '@sentry/vue'

const ignore500Regex = /(code 5[0-9]{2})/gm
const ignore401and403Regex = /(code 40[1,3])/gm

export default {
  install (Vue, config) {
    Sentry.init({
      Vue,
      dsn: config.dsn,
      ignoreErrors: [
        ignore500Regex,
        ignore401and403Regex,
        'Navigation cancelled'
      ],
      environment: config.environment,
      release: config.release,
      beforeSend (event) {
        // ignore error tracking for dev instances and only send for qa/staging/prod
        if (event.environment && event.environment === 'development') return
        return event
      },
      integrations: [
        Sentry.browserTracingIntegration({ router: config.router }),
        Sentry.replayIntegration({
          blockAllMedia: false,
          maskAllInputs: false,
          maskAllText: false
        })
      ],
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 0.05,
      replaysOnErrorSampleRate: 1.0
    })
    // tag the application with the appropriate actor (frontend->holder, frontend_admin->issuer)
    Sentry.setTag('role', config.role)
    const majorVueVersion = Number(Vue.version.split('.')[0])
    if (majorVueVersion >= 3) {
      Vue.config.globalProperties.$logException = exception => Sentry.captureException(exception)
      Vue.config.globalProperties.$attachSessionData = data => Sentry.setUser(data)
    } else {
      Vue.prototype.$logException = exception => Sentry.captureException(exception)
      Vue.prototype.$attachSessionData = data => Sentry.setUser(data)
    }
  }
}
