import AxuallID from './credentials/AxuallID.vue'
import AxuallTraining from './credentials/AxuallTraining.vue'
import BoardCertificationABPN from './credentials/BoardCertificationABPN.vue'
import BoardCertificationNA from './credentials/BoardCertificationNA.vue'
import BoardCertificationNP from './credentials/BoardCertificationNP.vue'
import BoardCertificationPA from './credentials/BoardCertificationPA.vue'
import BoardCertificationGeneric from './credentials/BoardCertificationGeneric.vue'
import BoardLicense from './credentials/BoardLicense.vue'
import CDS from './credentials/CDS.vue'
import DEA from './credentials/DEA.vue'
import Education from './credentials/Education.vue'
import FindingsReported from './credentials/FindingsReported.vue'
import Exclusions from './credentials/Exclusions.vue'
import HospitalAffiliation from './credentials/HospitalAffiliation.vue'
import LifeSupportAHA from './credentials/LifeSupportAHA.vue'
import NPDB from './credentials/NPDB.vue'
import NPI from './credentials/NPI.vue'
import NSC from './credentials/NSC'
import ODM from './credentials/ODM.vue'
import OFAC from './credentials/OFAC.vue'
import OptOut from './credentials/OptOut.vue'
import ParticipationReported from './credentials/ParticipationReported.vue'
import SAM from './credentials/SAM.vue'
import StateLicense from './credentials/StateLicense'
import Training from './credentials/Training.vue'
import Unknown from './credentials/Unknown.vue'
import WorkGap from './credentials/WorkGap.vue'
import WorkTraining from './credentials/WorkTraining.vue'
import WorkVerification from './credentials/WorkVerification.vue'

const mapper = {
  aha_cert: LifeSupportAHA,
  axuall_id: AxuallID,
  axuall_training_certificate: AxuallTraining,
  board_certification_abpn: BoardCertificationABPN,
  board_certification_na: BoardCertificationNA,
  board_certification_np: BoardCertificationNP,
  board_certification_pa: BoardCertificationPA,
  board_certification_generic: BoardCertificationGeneric,
  board_certification: BoardLicense,
  cds: CDS,
  cms: OptOut,
  dea: DEA,
  education: Education,
  findings_reported: FindingsReported,
  hospital_affiliation: HospitalAffiliation,
  npdb: NPDB,
  nppes: NPI,
  nsc_education: NSC,
  odm: ODM,
  ofac: OFAC,
  oig_hhs: Exclusions,
  participation_reported: ParticipationReported,
  rn_license: StateLicense,
  sam: SAM,
  state_license: StateLicense,
  training: Training,
  university_transcript: Education,
  work_gap: WorkGap,
  work_training: WorkTraining,
  work_verification: WorkVerification
}

export default {
  install (Vue) {
    Vue.prototype.$credential = (schemaName) => {
      if (mapper[schemaName]) return mapper[schemaName]
      return Unknown
    }
  }
}
