import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export const state = () => {
  return {
    documents: []
  }
}

export const mutations = {
  setDocuments (state, documents) {
    state.documents = documents
  }
}

export const actions = {
  get (context, providerID) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/clinician_documents?provider_id=${providerID}`
    }).then(({ data }) => {
      context.commit('setDocuments', data.data)
      return data.data
    })
  },
  upload (context, formData) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'post',
      url: '/api/clinician_documents',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(({ data }) => {
      context.commit('setDocuments', data.data)
      return data.data
    })
  },
  download (context, { path, providerID }) {
    const axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/clinician_documents/${path}?provider_id=${providerID}`
    }).then(({ data }) => {
      return data.data
    })
  }
}

export default {
  state,
  mutations,
  actions
}
