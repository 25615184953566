import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export default {
  namespaced: true,

  state: {
    self_attested: {
      'basic_info|other_names': []
    },
    board_cert_config: []
  },

  mutations: {
    setData (state, attrs) {
      state.self_attested[attrs.key] = attrs.val
    },
    addAttestedAlias (state, alias) {
      state.self_attested['basic_info|other_names'].push(alias)
    },
    setBoardCertConfig (state, boardCertConfig) {
      state.board_cert_config = boardCertConfig
    }
  },

  actions: {
    patchAlias (context, providerID) {
      const axios = createAuthInstance(context, createXSRFInstance())
      const data = {}

      data['basic_info|other_names'] = context.state.self_attested['basic_info|other_names']

      return axios({
        method: 'post',
        url: `/api/add_alias/${providerID}`,
        data: {
          data
        }
      })
    },
    updateSelfAttested (context, { providerID, data }) {
      const axios = createAuthInstance(context, createXSRFInstance())

      return axios({
        method: 'put',
        url: `/api/self_attested/${providerID}`,
        data: { data }
      }).then(({ data }) => {
        return data.data
      })
    },
    addSelfAttested (context, data) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'post',
        url: `/api/self_attested/${data.providerID}`,
        data: { data: data.data }
      }).then(({ data }) => {
        return data.data
      })
    },
    addSelfAttestedAffiliation (context, data) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'post',
        url: '/api/affiliations',
        data
      }).then(({ data }) => {
        return data.data
      })
    },
    updateSelfAttestedAffiliation (context, data) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'put',
        url: '/api/affiliations',
        data
      }).then(({ data }) => {
        return data.data
      })
    },
    addSelfAttestedWorkHistory (context, data) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'post',
        url: '/api/work_history',
        data
      }).then(({ data }) => {
        return data.data
      })
    },
    updateSelfAttestedWorkHistory (context, data) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'put',
        url: '/api/work_history',
        data
      }).then(({ data }) => {
        return data.data
      })
    },
    getBoardCertConfig (context, providerType) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'get',
        url: '/api/board_cert_config',
        params: {
          provider_type: providerType
        }
      }).then(({ data }) => {
        context.commit('setBoardCertConfig', data.data)
        return data.data
      })
    }
  }
}
