import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'
import router from '@/router'

export const state = () => {
  return {
    presentations: [],
    attestations: {},
    self_attested: [],
    questions: [],
    approvalInfo: [],
    subscribers: [],
    flagged: [],
    count: 0,
    loading: false,
    timeChecked: '',
    initialPresentationDate: '',
    details: {},
    app_req: {},
    providerID: '',
    billing_status: '',
    activeTab: 'checklist'
  }
}

const defaultState = state()

export const mutations = {
  setPresentations (state, data) {
    state.attestations = data.attestations ? data.attestations.attributes : {}
    state.self_attested = data.self_attested
    state.billing_status = data.billing_status
    const hasAttestations = Object.keys(data.attestations).length
    state.approvalInfo = data.approval_info
    state.flagged = data.flagged
    state.app_req = data.app_req || {}
    state.timeChecked = hasAttestations ? data.attestations.time_checked : ''
    state.initialPresentationDate = data.initial_presentation_date ? data.initial_presentation_date.time_checked : ''
    state.questions = data.app_req?.attested_reqs?.questions || []
    state.providerID = data.provider_id
    state.presentations = data.presentations.sort((a, b) => {
      if (a.schema < b.schema) {
        return -1
      }
      return 1
    })
    if (data.presentations.length) {
      state.count = data.presentations[0].full_count
    }
    state.subscribers = data.subscribers || []
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setDetails (state, data) {
    state.details = data
  },
  setActiveTab (state, tab) {
    state.activeTab = tab
  },
  reset (state) {
    state = defaultState
  }
}

export const actions = {
  retrieveDetails (context, providerID) {
    const axios = createAuthInstance(context, createXSRFInstance())
    context.commit('setLoading', true)
    return axios({
      method: 'get',
      url: `/api/subscribed_holders/${providerID}`
    }).then(({ data }) => {
      context.commit('setDetails', data.data)
      return data.data
    }).finally(() => context.commit('setLoading', false))
  },
  retrievePresentations (context, params) {
    const axios = createAuthInstance(context, createXSRFInstance())
    context.commit('setLoading', true)
    context.commit('reset')
    return axios({
      method: 'get',
      url: '/api/presentations',
      params
    }).then(({ data }) => {
      context.commit('setPresentations', data.data)
      return data.data
    }, () => {
      router.push('/dashboard')
    }).finally(() => context.commit('setLoading', false))
  },
  retrieveProof (context, data) {
    let axios = createXSRFInstance()
    context.commit('setLoading', true)
    if (data.subscriber) {
      return context.dispatch('auth/retrieveAccessToken', null, { root: true }).then((accessToken) => {
        return axios({
          method: 'get',
          url: `/api/presentations/proof/${data.proofID}?latest_proof_date=${data.latestProofDate}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'X-Axuall-Actor': data.subscriber
          }
        }).then((response) => {
          return response
        }).finally(() => context.commit('setLoading', false))
      })
    }
    axios = createAuthInstance(context, createXSRFInstance())
    return axios({
      method: 'get',
      url: `/api/presentations/proof/${data.proofID}?latest_proof_date=${data.latestProofDate}`
    }).then((response) => {
      return response
    }).finally(() => context.commit('setLoading', false))
  },
  requestPresentation (context, { actorId, email, name, appReq }) {
    const axios = createAuthInstance(context, createXSRFInstance())
    context.commit('setLoading', true)
    return axios({
      method: 'post',
      url: '/api/share',
      data: {
        email: email,
        name: name,
        app_req: appReq
      }
    }).then((response) => {
      return response
    }).finally(() => context.commit('setLoading', false))
  },
  retrieveAttestation (context, signature) {
    const axios = createXSRFInstance()
    return axios({
      method: 'get',
      url: `/api/get_document/${signature}`
    }).then(({ data }) => {
      return data.data
    })
  },
  updateChecklistItem (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    context.commit('setLoading', true)
    return axios({
      method: 'patch',
      url: '/api/checklist',
      data: {
        their_did: data.theirDid,
        criteria: data.criteria,
        activity: data.activity
      }
    }).then(({ data }) => {
      return data.data
    }).finally(() => context.commit('setLoading', false))
  },
  updateChecklistActivity (context, data) {
    const axios = createAuthInstance(context, createXSRFInstance())
    context.commit('setLoading', true)
    return axios({
      method: 'patch',
      url: '/api/presentations',
      data: {
        their_did: data.theirDid,
        activity: data.activity
      }
    }).then(({ data }) => {
      return data.data
    }).finally(() => context.commit('setLoading', false))
  }
}

export const getters = {
  providerType (state) {
    if (state.attestations['basic_info|occupation_code']) {
      return state.attestations['basic_info|occupation_code'].name
    }
    return ''
  },
  category (state) {
    return (category) => {
      return state.presentations.filter((p) => {
        if (category === 'licenses') {
          return ((p.schema_category === category) || (p.schema_category === 'other_certs'))
        } else {
          return (p.schema_category === category)
        }
      })
    }
  },
  approvalInfo (state) {
    if (state.approvalInfo.length) {
      return state.approvalInfo[0]
    }
  },
  fullName (state) {
    if (Object.keys(state.attestations).length) {
      return `${state.attestations['basic_info|first_name']}
              ${state.attestations['basic_info|last_name']},
              ${state.attestations['basic_info|occupation_code']?.name}`
    }
    return ''
  },
  sortedChecklist (state) {
    const digits = /(\d+)/
    return {
      ...state.checklist,
      checklist: state.checklist.checklist.concat().sort((a, b) => {
        // Retrieves first set of digits from criteria string, accounting for multiple shapes.
        // Examples: 'ncqa_cvo4', 'ncqa_cvo12_13'
        const x = Number(a.criteria.match(digits)[0])
        const y = Number(b.criteria.match(digits)[0])
        return x - y
      })
    }
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
