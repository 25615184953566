import { createAuthInstance, createXSRFInstance } from 'axios-interceptor'

export default {
  namespaced: true,

  state: {
    activeProfile: {},
    clinicianIds: []
  },

  mutations: {
    setActiveProfile (state, payload) {
      if (payload) {
        state.activeProfile = {
          actor: payload?.actor,
          cycle_type: payload?.cycle_type,
          first_name: payload?.first_name,
          last_name: payload?.last_name,
          provider_id: payload?.provider_id,
          their_did: payload?.their_did,
          reviewed: payload?.reviewed,
          event_type: payload?.event_type,
          degree: payload?.provider_types
            ? payload.provider_types[0].match(/\((\w+)\)/)[1]
            : '',
          profile_url: payload?.their_did
            ? `/presentations/${payload.their_did}`
            : ''
        }
      }
    },
    clearActiveProfile (state) {
      state.activeProfile = {}
    },
    setClinicianIds (state, data) {
      state.clinicianIds = data
    },
    addClinicianId (state, providerId) {
      state.clinicianIds.push(providerId)
    },
    removeClinicianId (state, providerId) {
      state.clinicianIds = state.clinicianIds.filter(item => item !== providerId)
    }
  },

  getters: {
    isDeactivated (state, _, rootState) {
      return rootState.presentation.billing_status === 'deactivated' ||
        state.activeProfile?.event_type === 'deactivated'
    }
  },

  actions: {
    getMyClinicians (context, params) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'get',
        url: '/api/my_clinicians',
        params
      }).then(({ data }) => {
        context.commit('setClinicianIds', data.data)
        return data.data
      })
    },
    assignClinician (context, userId) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'post',
        url: '/api/clinician/assign',
        data: {
          user_id: userId,
          provider_id: context.state.activeProfile.provider_id
        }
      }).then(({ data }) => {
        context.commit('addClinicianId', context.state.activeProfile.provider_id)
      })
    },
    unassignClinician (context, userId) {
      const axios = createAuthInstance(context, createXSRFInstance())
      return axios({
        method: 'post',
        url: '/api/clinician/unassign',
        data: {
          user_id: userId,
          provider_id: context.state.activeProfile.provider_id
        }
      }).then(({ data }) => {
        context.commit('removeClinicianId', context.state.activeProfile.provider_id)
      })
    },
    deactivateClinician (context) {
      const axios = createAuthInstance(context, createXSRFInstance())

      return axios({
        method: 'POST',
        url: `/api/v2/providers/${context.state.activeProfile.provider_id}/deactivation`
      })
    }
  }
}
